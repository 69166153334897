<template>
    <b-container fluid>
        <HomeAnalytics :key="$i18n.locale"></HomeAnalytics>
    </b-container>
</template>

<script>
import HomeAnalytics from "@/components/analytics/HomeAnalytics";
export default {
    name: "Analytics",
    components: {HomeAnalytics}
}
</script>

<style scoped>

</style>