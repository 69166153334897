<template>
    <div
        class="col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center">
        <div class="d-flex w-100 justify-content-center flex-wrap">
            <b-col class="col-xl-7 col-md-12 mt-3">
                <div>
                    <p class="container-title mb-0">{{ $t('analytics.pollenBySensors.title') }}</p>
                    <p class="h6">{{ $t('analytics.pollenBySensors.subtitle') }}</p>
                </div>
                <apexchart ref="chart" :height="600" :options="pollenBySensorChartOptions"
                           :series="pollenBySensorChartOptions.series" v-if="pollenBySensor"/>
                <div v-else class="d-flex w-100 align-items-center justify-content-center"
                     style="height: 300px">
                    <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
                </div>
            </b-col>
            <div
                class="col-xl-5 col-lg-10 justify-content-xl-start justify-content-center align-content-start">
                <div class="w-100 mt-3">
                    <p class="container-title mb-0">{{ $t('analytics.riskBySensors.title') }}</p>
                    <p class="h6">{{ $t('analytics.riskBySensors.subtitle') }}</p>
                </div>
                <div v-if="!riskBySensor" class="d-flex w-100 align-content-center justify-content-center">
                    <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
                </div>
                <div v-else-if="donutList.length === 0"
                     class="d-flex flex-wrap justify-content-xl-start justify-content-center h6">
                    No Data
                </div>
                <div v-else class="d-flex flex-wrap justify-content-xl-start justify-content-center h6">
                    <div v-for="(i, idx) in donutList" :key="idx">
                        <apexchart style="margin:auto" ref="chart" :width="donutOptions.chart.width"
                                   :height="donutOptions.chart.height"
                                   :options="donutOptions" :series="i.series"/>
                        <p class="text-center" style="word-break: break-word;inline-size:150px;">{{
                                i.sensor
                            }}</p>
                    </div>
                </div>
            </div>
        </div>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.distributionRisk.title') }}</p>
            <p class="h6">{{ $t('analytics.distributionRisk.subtitle') }}</p>
            <apexchart ref="chart" :height="250" :options="chartOptions" :series="yearlyRisk"
                       v-if="yearlyRiskL"></apexchart>
            <div v-else class="d-flex w-100 align-items-center justify-content-center"
                 style="height: 300px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
        </b-col>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.distributionRiskBySensor.title') }}</p>
            <p class="h6">{{ $t('analytics.distributionRiskBySensor.subtitle') }}</p>
            <apexchart ref="chart" :options="chartOptions" :series="yearlyRiskBySensor" :height="250"
                       v-if="yearlyRiskBySensorL"/>
            <div v-else class="d-flex w-100 align-items-center justify-content-center"
                 style="height: 400px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
        </b-col>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.pollenCalendar.title') }}</p>
            <p class="h6">{{ $t('analytics.pollenCalendar.subtitle') }}</p>
            <apexchart ref="chart" :options="chartOptions" :series="riskPerPollen" :height="400"
                       v-if="riskPerPollenL"/>
            <div v-else class="d-flex w-100 align-items-center justify-content-center"
                 style="height: 300px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
        </b-col>
    </div>
</template>

<script>

import Vuex from "vuex";
import moment from "moment/moment";
// import moment from "moment";

export default {
    name: "SensorAnalytics",
    components: {},
    props: {
        startDate: String,
        endDate: String,
        stage: Number,
        update: Boolean,
        loading: Boolean,
        chartOptions: Object
    },
    data() {
        return {
            pollenBySensorChartOptions: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            total: {enabled: true},
                        },
                    },
                },
                series: [],
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    type: 'text',
                    categories: []
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                fill: {
                    colors: ['#D9534F', '#FF9C5B', '#F6C90F', '#4BA68D', '#F5F5F5', '#FFFFFF']
                },
                legend: {
                    show: false
                },
                noData: {
                    text: 'Aucune donnée'
                },
                tooltip: {
                    y: {
                        title: {
                            formatter: (title) => {
                                return title + ' :'
                            }
                        },
                        formatter: (val) => {
                            return val + 'j'
                        }
                    }
                }
            },
            donutOptions: {
                chart: {
                    type: "donut",
                    animations: {
                        enabled: false
                    },
                    width: '150',
                    height: '150'
                },
                markers: {
                    size: 0
                },
                series: [],
                colors: ['#D9534F', '#FF9C5B', '#F6C90F', '#4BA68D', '#F5F5F5'],
                legend: {
                    show: false
                },
                stroke: {
                    width: 0
                },
                dataLabels: {
                    enabled: false,
                },
                noData: {
                    text: 'Aucune donnée'
                },
                tooltip: {
                    fillSeriesColor: false,
                    theme: 'light',
                    y: {
                        title: {
                            formatter: (val) => {
                                switch (val) {
                                    case 'series-5':
                                        return 'aucun pollen' + ' : ';
                                    case 'series-4':
                                        return this.$t('risk.0') + ' ' + this.$t('risk.this') + ' : ';
                                    case 'series-3':
                                        return this.$t('risk.this') + ' ' + this.$t('risk.1') + ' : ';
                                    case 'series-2':
                                        return this.$t('risk.this') + ' ' + this.$t('risk.2') + ' : ';
                                    case 'series-1':
                                        return this.$t('risk.this') + ' ' + this.$t('risk.3') + ' : ';
                                }
                            }
                        },
                        formatter: (val) => {
                            return val + 'j'
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    fontSize: 11,
                                    color: '#000000',
                                    formatter: (val) => {
                                        switch (val) {
                                            case 'series-5':
                                                return this.$t('risk.0');
                                            case 'series-4':
                                                return this.$t('risk.0');
                                            case 'series-3':
                                                return this.$t('risk.1');
                                            case 'series-2':
                                                return this.$t('risk.2');
                                            case 'series-1':
                                                return this.$t('risk.3');
                                            default:
                                                return 'Total'
                                        }
                                    }
                                },
                                value: {
                                    show: true,
                                    formatter: (val) => {
                                        return val + 'j'
                                    }
                                },
                                total: {
                                    show: true,
                                    label: 'Total',
                                    fontSize: '11px',
                                    formatter: (value) => {
                                        return value.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0) + 'j'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            yearlyRisk: [],
            donutList: [],
            yearlyRiskBySensor: [],
            riskPerPollen: [],
            pollenRiskYearlyChart: [],
            pollenRiskYearly: [],
            riskPerPollenL: false,
            yearlyRiskBySensorL: false,
            pollenBySensor: false,
            riskBySensor: false,
            days: [],
            yearlyRiskL: false,
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics', {
            getAccountAnalytics: 'getAccountAnalytics',
        }),
        dDiff() {
            return moment(this.endDate).diff(this.startDate, "days")
        },
    },
    methods: {
        async delay(ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            })
        },
        async setDays() {
            this.days = [];
            for (let i = 0; i < this.dDiff + 1; i++) {
                this.days.push({x: moment(this.startDate).add(i, "day").format('YYYY-MM-DD'), y: -2})
            }
        },
        async setPollenBySensor() {
            let d = this.getAccountAnalytics.daysOfPollenPresence;
            this.pollenBySensorChartOptions.series = [];
            this.pollenBySensorChartOptions.xaxis.categories = [];

            let highRisk = [];
            let mediumRisk = [];
            let lowRisk = [];
            let noRisk = [];

            const sortedObj = Object.entries(d)
                .sort((
                    [, val1], [, val2]) => (val2[3] - val1[3]) || val2[2] - val1[2] || val2[1] - val1[1] || val2[0] - val1[0]
                )
                .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});

            for (let pollen in sortedObj) {
                noRisk.push(d[pollen][0]);
                lowRisk.push(d[pollen][1]);
                mediumRisk.push(d[pollen][2]);
                highRisk.push(d[pollen][3]);

                if (!this.pollenBySensorChartOptions.xaxis.categories.find(p => p === this.$t('pollen.type.' + pollen))) {
                    this.pollenBySensorChartOptions.xaxis.categories.push(this.$t('pollen.type.' + pollen));
                }

            }
            this.pollenBySensorChartOptions.series.push(
                {"name": this.$t('risk.this') + ' ' + this.$t('risk.3'), "data": highRisk},
                {"name": this.$t('risk.this') + ' ' + this.$t('risk.2'), "data": mediumRisk},
                {"name": this.$t('risk.this') + ' ' + this.$t('risk.1'), "data": lowRisk},
                {"name": this.$t('risk.0') + ' ' + this.$t('risk.this'), "data": noRisk}
            )
        },
        async setRiskBySensor() {
            let d = this.getAccountAnalytics.highestRiskSensor;
            this.donutList = [];
            for (let sensor in d) {
                let high = 0, medium = 0, low = 0, no = 0, noData = 0;
                for (let i = 0; i < d[sensor].length; i++) {
                    switch (d[sensor][i].riskLevel) {
                        case null:
                            noData++;
                            break;
                        case 0:
                            no++;
                            break;
                        case 1:
                            low++;
                            break;
                        case 2:
                            medium++;
                            break;
                        case 3:
                            high++;
                            break;
                    }
                }
                this.donutList.push({sensor: sensor, series: [high, medium, low, no, noData]})
            }
        },
        async setYearlyRisk() {
            let highestRiskSensor = this.getAccountAnalytics.highestRiskSensor;
            this.yearlyRisk = [];
            if (highestRiskSensor.length === 0) {
                return;
            }
            const data = JSON.parse(JSON.stringify(this.days));
            const hash = {};
            this.yearlyRisk.push({name: "                      ", data: data});

            // Create hash table
            for (let i = 0; i < this.yearlyRisk[0].data.length; i++) {
                const item = this.yearlyRisk[0].data[i];
                hash[moment(item.x).format('YYYY-MM-DD')] = item;
            }

            // Update data array
            for (let s in highestRiskSensor) {
                highestRiskSensor[s].forEach((s) => {
                    const date = moment(s.date).format('YYYY-MM-DD');
                    let item = hash[date];
                    if (item) {
                        if (s.riskLevel === null) {
                            if (item.y === -2) {
                                item.y = -1
                            } else {
                                item.y = item.y === null ? -1 : item.y;
                            }
                        } else {
                            item.y = item.y >= s.riskLevel ? item.y : s.riskLevel;
                        }
                    }
                });
            }
        },
        async setYearlyRiskBySensor() {
            let highestRiskSensor = this.getAccountAnalytics.highestRiskSensor;
            this.yearlyRiskBySensor = [];
            if (highestRiskSensor.length === 0) {
                return;
            }
            for (let s in highestRiskSensor) {
                const data = JSON.parse(JSON.stringify(this.days));

                let t = {
                    name: s,
                    data: data
                }
                highestRiskSensor[s].forEach((x) => {
                    t.data.find((item) => {
                        if (item.x === x.date) {
                            x.riskLevel === null ? item.y = -1 : item.y = x.riskLevel;
                        }
                        if (item.x === x.date) {
                            if (x.riskLevel === null) {
                                if (item.y === -2) {
                                    item.y = -1
                                } else {
                                    item.y = item.y === null ? -1 : item.y;
                                }
                            } else {
                                item.y = item.y < x.riskLevel ? x.riskLevel : item.y;
                            }
                        }
                    })
                })
                this.yearlyRiskBySensor.push(t);
            }
        },
        setLoading() {
            this.pollenBySensor = false;
            this.riskBySensor = false;
            this.yearlyRiskL = false;
            this.yearlyRiskBySensorL = false;
            this.riskPerPollenL = false;
        },
        async setRiskPerPollen() {
            let highestRiskSensor = this.getAccountAnalytics.highestRiskPollen;
            this.riskPerPollen = [];
            if (highestRiskSensor.length === 0) {
                return;
            }
            for (let p in highestRiskSensor) {
                const data = JSON.parse(JSON.stringify(this.days));
                let t = {name: this.$t('pollen.type.' + p), data: data};
                for (let i = 0; i < highestRiskSensor[p].length; i++) {
                    t.data.find((item) => {
                        if (item.x === highestRiskSensor[p][i][0]) {
                            if (highestRiskSensor[p][i][1] === null) {
                                if (item.y === -2) {
                                    item.y = -1
                                } else {
                                    item.y = item.y === null ? -1 : item.y;
                                }
                            } else {
                                item.y = item.y < highestRiskSensor[p][i][1] ? highestRiskSensor[p][i][1] : item.y;
                            }
                        }
                    })
                }

                this.riskPerPollen.push(t);
            }
            this.riskPerPollen.sort((a, b) => (b.name > a.name) ? 1 : ((a.name > b.name) ? -1 : 0))
        },
        async render() {
            await this.setDays();
            await this.setPollenBySensor();
            this.pollenBySensor = true;
            //
            await this.setRiskBySensor();
            this.riskBySensor = true;
            await this.delay(100)
            //
            await this.setYearlyRisk()
            this.yearlyRiskL = true;
            await this.delay(100)

            //
            await this.setYearlyRiskBySensor()
            this.yearlyRiskBySensorL = true;
            await this.delay(100)

            await this.setRiskPerPollen()
            this.riskPerPollenL = true;
            this.$emit('done');
        }
    },
    created() {
    },
    watch: {
        loading: async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.setLoading();
            }
        },
        stage: async function (newVal) {
            if (newVal === 1) {
                await this.render();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.apexcharts-toolbar {
    z-index: 0 !important;
}

.pollen-square-detail {
    padding: 1px 5px;
    margin: 1px 5px;
}
</style>
