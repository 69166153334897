<template>
    <div>
        <div id="my-legend-chart">
            <b-collapse id="legends">
                <div class="legend-item py-3">
                <div class="rounded-circle" style="width: 20px;height: 20px;background: #D9534F"></div>
                <span style="color: #D9534F">{{ $t('common.chart.chartLegend.3') | capitalize }}</span>
                </div>
                <div class="legend-item py-3">
                    <div class="rounded-circle" style="width: 20px;height: 20px;background: #FF9C5B"></div>
                    <span style="color: #FF9C5B">{{ $t('common.chart.chartLegend.2') | capitalize }}</span>
                </div>
                <div class="legend-item py-3">
                    <div class="rounded-circle" style="width: 20px;height: 20px;background: #F6C90F"></div>
                    <span style="color: #F6C90F">{{ $t('common.chart.chartLegend.1') | capitalize }}</span>
                </div>
                <div class="legend-item py-3">
                    <div class="rounded-circle" style="width: 20px;height: 20px;background: #4ba68d"></div>
                    <span style="color: #4BA68D">{{ $t('common.chart.chartLegend.0') | capitalize }}</span>
                </div>
                <div class="legend-item py-3">
                    <div class="rounded-circle" style="width: 20px;height: 20px;background: #F5F5F5;border:1px solid #D4D4D4;"></div>
                    <span style="color: #636D73">{{ $t('common.chart.chartLegend.null') | capitalize }}</span>
                </div>
                <div class="legend-item py-3">
                    <div class="rounded-circle" style="width: 20px;height: 20px;background: #FFF;border:1px solid #D4D4D4;"></div>
                    <span style="color: #636D73">{{ $t('common.chart.chartLegend.noData') | capitalize }}</span>
                </div>
            </b-collapse>
            <div class="legend-item default py-3" v-b-toggle.legends>
                <div class="rounded-circle" style="width: 20px;height: 20px;background: #FFF"></div>
                <span style="color: #FFF">{{ $t('common.chart.chartLegend.this') | capitalize }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChartLegend"
}
</script>

<style lang="scss" scoped>
#my-legend-chart{
    position:fixed;
    bottom: 50px;
    right: 30px;
    width: 100px;
    background:#D8D8D8;
    .legend-item{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        background: #FFF;
        box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
        font-size: 14px;
        text-align: center;
        display:flex;
        >div{
            min-width: 20px;
            min-height: 20px;
            width: 20px;
            height: 20px;
        }
    }
    .default{
        background: $orange-lifyair;
        color: #FFF;
    }
}
</style>
