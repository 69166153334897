<template>
    <div class="container-fluid bg-white min-vh-100">
        <b-row class="py-2 min-vh-100 align-content-start" align-h="center" align-v="start">
            <b-row v-if="currentAccount && showPollenDetail" class="w-100">
                <b-col :cols="isBanner ? 10 : 12">
                    <p class="big-title mb-0" v-b-tooltip.hover.bottomleft
                       :title="currentAccount.name">
                        <span @click="showPollenDetail = false" class="add-cursor">
                            <b-icon icon="chevron-left"/>
                            {{ accountName | capitalize }}
                        </span>
                        <b-icon icon="caret-right-fill" class="clr-lifyair"/>
                        {{ $t(`pollen.type.${selectedRollingRisk.name}`) | capitalize }}
                    </p>
                </b-col>
                <b-col cols="2" v-if="isBanner" class="text-right">
                    <b-img class="logo-live-pollen" src="@/assets/images/icon/logo_header.svg"></b-img>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <div class="divider"></div>
                </b-col>
            </b-row>
            <b-row v-else-if="currentAccount" class="col-12 no-gutters">
                <b-col cols="10">
                    <p class="big-title mb-0" v-b-tooltip.hover.bottomleft :title="currentAccount.name">
                        {{ accountName | capitalize }}</p>
                </b-col>
                <b-col cols="2">
                    <b-img class="float-right logo-live-pollen" src="@/assets/images/icon/logo_header.svg"></b-img>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <div class="divider"></div>
                </b-col>
            </b-row>
            <b-skeleton animation="wave" v-else></b-skeleton>
            <b-col cols="12" v-if="globalActualRisksLoading || loadingStatus || sensors.length === 0">
                <b-skeleton animation="wave"></b-skeleton>
                <b-skeleton animation="wave"></b-skeleton>
                <b-skeleton animation="wave"></b-skeleton>
            </b-col>
            <b-col cols="12" v-else-if="showPollenDetail">
                <WidgetPollenDetail :is-banner="isBanner"/>
            </b-col>
            <b-col cols="12" v-else>
                <b-row align-v="start" align-h="between">
                    <b-col cols="12" md="8" v-if="isSensorOn">
                        <b-row v-if="hasPollenBeenDetected" align-h="start">
                            <b-col cols="12" class="row no-gutters justify-content-start align-items-end">
                                <p class="section-title mb-0" :class="isBanner ? 'col-auto' : 'col-12'">
                                    {{ $t('common.title.detectedSpecies') | capitalize }}
                                </p>
                                <p class="subtitle mb-0" :class="isBanner ? 'col-auto ml-2' : 'col-12'">
                                    {{ $t('common.title.detectedSpeciesSub') }}
                                </p>
                            </b-col>
                            <b-row
                                class="m-2 row align-items-center justify-content-start w-100"
                                :class="isBanner ? 'justify-content-start': 'justify-content-center'">
                                <div v-for="(risk, index) in formattedDetectedRisks[currentPage]"
                                     :key="index" :class="isBanner ? 'col-3' : 'col-6'">
                                    <div class="pollen-card">
                                        <div class="d-flex align-items-center py-1">
                                            <b-img :src="getImage(risk.name)" width="33" height="33"
                                                   class="mr-1"></b-img>
                                            <p class="m-0 pollen-title" :style="{fontSize: fontSize(risk.name)}">
                                                {{ $t(`pollen.type.${risk.name}`) }}</p>
                                        </div>
                                        <div style="border-radius: 0 0 2px 2px" class="py-1"
                                             :class="`bg-${riskColor(risk.riskLevel)}`">
                                            <span :class="clrByRisk(risk.riskLevel)">
                                                {{ $t(`risk.${risk.riskLevel + 'Risk'}`) | capitalize }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                            <b-col cols="12" class="text-center mt-1" v-if="formattedDetectedRisks.length > 1">
                                <div class="dot mx-2" v-for="(n, i) in formattedDetectedRisks.length"
                                     @click="currentPage = i"
                                     :key="n" :class="currentPage === i ? 'active' : ''"/>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-around" v-else>
                            <b-col cols="12" class="row no-gutters justify-content-start align-items-end">
                                <p class="section-title mb-0" :class="isBanner ? 'col-auto' : 'col-12'">
                                    {{ $t('common.title.detectedSpecies') | capitalize }}
                                </p>
                                <p class="subtitle mb-0" :class="isBanner ? 'col-auto ml-2' : 'col-12'">
                                    {{ $t('common.title.detectedSpeciesSub') }}
                                </p>
                            </b-col>
                            <b-col cols="3" align-self="end" class="mt-2">
                                <IconBase icon-name="no-species-detected" viewBox="0 0 132 120"
                                          height="100%" width="100%">
                                    <IconNoPollen/>
                                </IconBase>
                            </b-col>
                            <b-col cols="9" align-self="center">
                                <p class="clr-dimgrey">
                                    {{ $t('widget.noSpeciesTitle') | capitalize }}
                                </p>
                                <p class="pollen-none m-0">
                                    {{ $t('widget.noSpecies', {name: accountName}) | capitalize }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="8" class="mb-2" v-else>
                        <b-col cols="12" class="row no-gutters justify-content-start align-items-end">
                            <p class="section-title mb-0" :class="isBanner ? 'col-auto' : 'col-12'">
                                {{ $t('common.title.detectedSpecies') | capitalize }}
                            </p>
                            <p class="subtitle mb-0" :class="isBanner ? 'col-auto ml-2' : 'col-12'">
                                {{ $t('common.title.detectedSpeciesSub') }}
                            </p>
                        </b-col>
                        <b-row align-v="center" align-h="center" class="mt-1 col-12">
                            <b-col cols="3" align-self="end" class="mt-2">
                                <IconBase viewBox="0 0 360 384" height="100%" width="100%">
                                    <IconMaintenance/>
                                </IconBase>
                            </b-col>
                            <b-col cols="9" align-self="center">
                                <p class="pollen-none m-0">
                                    {{ $t('emptyState.disconnectedWidget', {name: accountName}) | capitalize }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="4" class="mt-2 my-md-2" align-self="stretch">
                        <a :href="appLink" target="_blank">
                            <b-row class="basic-card no-gutters justify-content-around m-0 p-1 p-md-3 h-100"
                                   align-v="center">
                                <b-col cols="1" md="2">
                                    <IconBase viewBox="0 0 56 59" height="100%" width="100%">
                                        <IconAdviceDetected v-if="hasPollenBeenDetected && isSensorOn"/>
                                        <IconAdviceNotDetected v-else/>
                                    </IconBase>
                                </b-col>
                                <b-col cols="10" md="9" class="pr-0">
                                    <p class="pollen-advice m-0">
                                        {{ $t(`advice.${isSensorOn ? 'basicWidget' : 'disconnectedWidget'}`) }}
                                    </p>
                                </b-col>
                            </b-row>
                        </a>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vuex from 'vuex';
import moment from "moment";
import IconBase from "@/components/common/icons/IconBase";
import IconMaintenance from "@/components/common/icons/IconMaintenance";
import IconAdviceDetected from "@/components/common/icons/advice/IconAdviceDetected";
import IconAdviceNotDetected from "@/components/common/icons/advice/IconAdviceNotDetected";
import WidgetPollenDetail from "@/components/widget/WidgetPollenDetail";
import pollenService from "@/services/pollen/pollenService";
import IconNoPollen from "@/components/common/icons/IconNoPollen.vue";

clearInterval();

export default {
    name: "WidgetHome",
    components: {
        IconNoPollen,
        WidgetPollenDetail,
        IconMaintenance,
        IconAdviceDetected,
        IconAdviceNotDetected,
        IconBase
    },
    data() {
        return {
            currentPage: 0,
            sliderInterval: null,
            appLink: '',
            showPollenDetail: false,
            isBanner: true
        }
    },
    methods: {
        ...Vuex.mapActions('apiKey', {
            setApiKey: 'setApiKey',
            setApiKeySecret: 'setApiKeySecret'
        }),
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors'
        }),
        ...Vuex.mapActions('account', {
            setCurrentAccount: 'setCurrentAccount',
        }),
        ...Vuex.mapActions('rollingRisk', {
            setGlobalActualRollingRisks: 'setGlobalActualRollingRisks',
            setSelectedRollingRisk: 'setSelectedRollingRisk'
        }),
        queryForSensors() {
            let query = {}
            query.type = 'pollen'
            query.period = 'hours'
            query.periodStep = 24
            return query
        },
        getImage(pollen) {
            const images = require.context('@/assets/images/icon/pollen/', false, /\.svg$/)
            return images('./' + pollen + ".svg")
        },
        chunk(array, length = 3) {
            const chunks = [];
            let i = 0;
            const total = array.length;

            while (i < total) {
                chunks.push(array.slice(i, i += length));
            }

            return chunks;
        },
        launchSlider() {
            this.sliderInterval = setInterval(() => {
                if (this.currentPage < this.formattedDetectedRisks.length - 1) {
                    this.currentPage += 1;
                } else {
                    this.currentPage = 0;
                }
            }, 5000);
        },
        clearSlider() {
            clearInterval(this.sliderInterval);
        },
        setAppLink() {
            let os = '';
            const userAgent = window.navigator.userAgent,
                platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'];

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            if (os === 'iOS' || os === 'Mac') {
                this.appLink = 'https://apps.apple.com/fr/app/live-pollen/id1565521367';
            } else {
                this.appLink = 'https://play.google.com/store/apps/details?id=com.lifyair.livepollen';
            }
        },
        setRollingRisk(rollingRisk) {
            this.setSelectedRollingRisk(rollingRisk).then(() => {
                this.showPollenDetail = true;
            })
        },
        fontSize(pollen) {
            const length = this.$i18n.t('pollen.type.' + pollen).length
            if (length < 10) return '16px';
            if (length >= 13) return '11px';
            if (length >= 10) return '12px';
        },
        clrByRisk(risk) {
            return (risk === 3 || risk === 0) ? 'clr-white' : 'clr-darkslategrey'
        },
        riskColor(risk) {
            return pollenService.getColorByValue(risk);
        },
    },
    computed: {
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount',
        }),
        ...Vuex.mapGetters('sensor', {
            getLatestSensorUpdated: 'getLatestSensorUpdated',
            loadingStatus: 'loadingStatus',
            sensors: 'sensors',
        }),
        ...Vuex.mapGetters('rollingRisk', {
            hasPollenBeenDetected: 'hasPollenBeenDetected',
            globalActualRisksLoading: 'globalActualRisksLoading',
            getDetectedGlobalRisk: 'getDetectedGlobalRisk',
            selectedRollingRisk: 'selectedRollingRisk'
        }),
        isSensorOn() {
            const now = moment();
            return moment(this.getLatestSensorUpdated) >= now.subtract(24, 'hours');
        },
        accountName() {
            if (this.sensors.length === 1) {
                return this.sensors[0].sensorName;
            } else if (this.isBanner) {
                return this.currentAccount.name;
            } else {
                if (this.currentAccount.name) {
                    return this.currentAccount.name;
                } else {
                    return '';
                }
            }
        },
        formattedDetectedRisks() {
            return this.chunk(this.getDetectedGlobalRisk, 4);
        },
    },
    async created() {
        let q = this.$router.currentRoute.query
        await this.setApiKey(q.apiKey);
        await this.setApiKeySecret(q.apiKeySecret);
        this.backgroundColorMutated = q.backgroundColor
        this.borderColorMutated = q.borderColor
        this.textColorMutated = q.textColor
        this.iconColorMutated = q.iconColor
        this.isBanner = q.isBanner;
        this.title = q.title;
        await this.setCurrentAccount();
        this.setAppLink();
        let query = this.queryForSensors();
        await this.setSensors({params: query}).then(() => {
            this.setGlobalActualRollingRisks().then(() => {
                if (this.getDetectedGlobalRisk.length > 3) {
                    this.launchSlider();
                }
            });
        });
    }
}
</script>

<style lang="scss" scoped>
.logo-live-pollen {
    width: 60px;
}

.divider {
    @include divider($lightgrey, 100%, 2px)
}

.pollen-square {
    background-color: $cadetblue;
    border-radius: 4px;
    color: white;
    margin: 0;
    border: unset;
    text-align: center;

    &.detected {
        background-color: $dark-orange-lifyair;
    }
}

.pollen-square-title {
    padding: 7px 0;
    font-size: 1rem;
    margin: 0;
}

.pollen-square-detail {
    padding: 1px 5px;
    margin: 1px 5px;
    font-size: 0.9rem;
}

.dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid $dark-orange-lifyair;
    background-color: $white;

    &.active {
        background-color: $dark-orange-lifyair;
    }
}

.pollen-card {
    text-align: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    background-color: $white;
    border-radius: 2px;
    margin-bottom: 15px;

    .pollen-title {
        font-size: .9rem;
        color: #6A6F7D;
    }
}

.advice-card {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    background-color: $white;
}


.pollen-none {
    color: $lightslategrey;
    font-size: .9rem;
}

.pollen-advice {
    color: $lightslategrey;
    font-size: .8rem;
}
</style>
