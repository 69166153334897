<template>
    <b-container fluid class="min-vh-100">
        <b-row align-h="end" class="mt-1" v-if="authStatus.message">
            <div class="col-auto">
                {{ authStatus.message }}
            </div>
        </b-row>
        <b-row align-h="center" align-v="end" class="vh-100">
            <b-col class="m-0 mt-md-5 login-card">
                <b-row class="mt-5">
                    <b-col class="text-center mt-5">
                        <img src="@/assets/images/icon/logo_header.svg" width="160"/>
                    </b-col>
                </b-row>
                <b-row align-h="center" class="mb-5">
                    <b-col cols="10" md="8">
                        <div class="my-5 title">
                            {{
                                $t(isTokenValid ? 'form.title.choosePassword' : 'form.title.resetToken') | capitalize
                            }}
                        </div>
                        <b-form @submit.prevent="changePassword()" v-if="isTokenValid" class="form">
                            <b-form-group class="mb-5" :label="capitalize($t('form.field.newPassword'))">
                                <b-input-group>
                                    <b-form-input
                                        id="input-1"
                                        v-model="password"
                                        :type="showPassword ? 'text' : 'password'"
                                        required
                                        class="field"
                                    >
                                    </b-form-input>
                                    <b-input-group-prepend class="bg-snow add-cursor">
                                        <IconBase view-box="0 0 24 24" icon-color="#636D73" stroke-color="#636D73"
                                                  class-name="mt-2 mr-2" width="30px" height="22px"
                                                  @click.native="showPassword = !showPassword">
                                            <IconInvisible v-if="showPassword"/>
                                            <IconVisible v-else/>
                                        </IconBase>
                                    </b-input-group-prepend>
                                </b-input-group>
                                <b-form-text id="input-live-help" :class="showNotValidPassword ? 'error' : ''">
                                    {{ $t('form.helper.passwordConditions') | capitalize }}
                                </b-form-text>
                            </b-form-group>
                            <b-form-group class="mb-5" :label="capitalize($t('form.field.newPasswordConfirmation'))">
                                <b-input-group>
                                    <b-form-input
                                        id="input-2"
                                        v-model="passwordConfirmation"
                                        :type="showPasswordConfirmation ? 'text' : 'password'"
                                        required
                                        class="field"
                                    >
                                    </b-form-input>
                                    <b-input-group-prepend class="bg-snow add-cursor">
                                        <IconBase view-box="0 0 24 24" icon-color="#636D73" stroke-color="#636D73"
                                                  class-name="mt-2 mr-2" width="30px" height="22px"
                                                  @click.native="showPasswordConfirmation = !showPasswordConfirmation">
                                            <IconInvisible v-if="showPasswordConfirmation"/>
                                            <IconVisible v-else/>
                                        </IconBase>
                                    </b-input-group-prepend>
                                </b-input-group>
                                <b-form-text id="input-live-help" class="error" v-if="showNotEquals">
                                    {{ $t('form.helper.passwordNotEqual') | capitalize }}
                                </b-form-text>
                            </b-form-group>
                            <div class="text-center">
                                <b-button type="submit" class="btn-live-pollen" :disabled="arePasswordEmpty">
                                    {{ capitalize($t('form.button.validate')) }}
                                </b-button>
                            </div>
                            <div class="add-cursor text-center forgot-password">
                                <p @click="$router.back()" class="clr-lightslategrey">
                                    {{ $t('form.button.cancel') }}</p>
                            </div>
                        </b-form>
                        <b-form @submit.prevent="confirmResetToken()" class="form" v-else>
                            <b-form-group :label="capitalize($t('form.field.code'))">
                                <b-form-input id="input-token" v-model="token" type="number" required
                                              class="field"></b-form-input>
                                <b-form-text id="input-live-help">
                                    {{ $t('form.helper.code') | capitalize }}
                                </b-form-text>
                            </b-form-group>
                            <div class="text-center">
                                <b-button type="submit" class="btn-live-pollen mt-5" :disabled="!token">
                                    {{ capitalize($t('form.button.validate')) }}
                                </b-button>
                            </div>
                            <div class="add-cursor text-center forgot-password">
                                <p @click="$router.back()" class="clr-lightslategrey">
                                    {{ $t('form.button.cancel') }}</p>
                            </div>
                        </b-form>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Vuex from 'vuex'
import IconBase from "@/components/common/icons/IconBase";
import IconVisible from "@/components/common/icons/IconVisible";
import IconInvisible from "@/components/common/icons/IconInvisible";

export default {
    name: "PasswordResetForm",
    components: {IconInvisible, IconVisible, IconBase},
    data() {
        return {
            password: '',
            passwordConfirmation: '',
            token: null,
            showPassword: false,
            showPasswordConfirmation: false,
            showNotEquals: false,
            showNotValidPassword: false,
        }
    },
    methods: {
        ...Vuex.mapActions('login', {
            resetPassword: 'resetPassword',
            confirmToken: 'confirmToken'
        }),
        async changePassword() {
            this.showNotValidPassword = !this.isPasswordValid;
            this.showNotEquals = !this.arePasswordEquals;
            if (this.isPasswordValid && this.arePasswordEquals) {
                let query = this.generateQueryString()
                await this.resetPassword(query);
            }
        },
        async confirmResetToken() {
            await this.confirmToken(this.token)
        },
        generateQueryString() {
            let data = []
            data.push(this.token)
            let fields = new FormData();
            fields.set('password', this.password)
            data.push(fields)
            return data
        },
        capitalize(value) {
            return this.$options.filters.capitalize(value)
        }
    },
    computed: {
        ...Vuex.mapGetters('login', {
            authStatus: 'authStatus',
            isTokenValid: 'getIsResetTokenValid'
        }),
        isPasswordValid() {
            let regex = new RegExp(/^(?=.*[a-zA-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/);
            return regex.test(this.password);
        },
        arePasswordEmpty() {
            return (!this.password || !this.passwordConfirmation)
        },
        arePasswordEquals() {
            if (this.password && this.passwordConfirmation) {
                return this.password === this.passwordConfirmation
            }
            return true
        },
    },
}
</script>

<style lang="scss" scoped>
.login-card {
    padding: 1vh 0;
    max-width: 600px;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

    .title {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.error {
    color: $indianred !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
