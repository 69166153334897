<template>
    <b-row class="home-analytics" align-h="center" align-v="start" align-content="start"
           style="height : calc(100vh); padding-top:80px">
        <div v-if="isPortrait" class="landscapeContainer">
            <div class="phone"></div>
            <div class="message">
                Tourne ton téléphone
            </div>
        </div>
        <div
            class="card analyticsFilter col-xl-10 py-2 px-1 mb-3 d-flex flex-wrap flex-row justify-content-start align-content-center align-items-center">

            <div class="monthList">
                <multiselect v-model="yearSelected" label="text" v-if="showYearSelection" :show-labels="false"
                             style="width: 160px;" :options="yearList" :class='selectedYear' :disabled="loading"
                             :placeholder="$t('analytics.season') + ' ' + getYear" size="lg" :searchable="false">
                    <template slot="singleLabel" slot-scope="{ option }">
                        <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                                  v-if="selectedYear === 'applied'">
                            <IconCheck/>
                        </IconBase>
                        <strong>{{ option.text }}</strong></template>
                </multiselect>

                <b-button variant="none" :disabled="loading" @click="selectFilters(btn)"
                          class="d-flex flex-nowrap align-items-center btn-live-pollen-filter mx-1 p-2"
                          :class="btn.class" :pressed.sync="btn.state"
                          v-for="(btn, idx) in buttons" :key="idx + 100">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="btn.class === 'applied'">
                        <IconCheck/>
                    </IconBase>
                    {{ btn.caption }}
                </b-button>
            </div>


            <div class="filters" v-if="isAuthorizedForAccountChoice">
                <b-spinner style="color: #FF5A00" label="Spinning" small v-if="accountOptions.length === 0"></b-spinner>
                <multiselect v-model="accountSelected" label="text" :show-labels="false" :options="accountOptions"
                             :disabled="loading"
                             :placeholder="$t('form.field.accountsChoice') | capitalize" style="width: 160px;" v-else>
                    <template slot="singleLabel" slot-scope="{option}">{{ option.text }}</template>
                </multiselect>
            </div>

            <div class="filters">
                <b-button class="btn-live-pollen mx-2 p-2" v-if="!filtered"
                          :disabled="selectedFilters.start.length === 0 || loading" @click="submit()">
                    {{ $t('common.button.applyFilters') }}
                </b-button>
                <b-button class="btn-live-pollen secondary mx-2 p-2" v-else :disabled="loading" @click="clearFilter()">
                    {{ $t('common.button.removeFilters') }}
                </b-button>
            </div>
        </div>
        <h3 class="h3 my-3 text-center">
            {{
                $t('analytics.infoPollen', {
                    startDate: $options.filters.formattedDate(startDate),
                    endDate: $options.filters.formattedDate(endDate)
                })
            }}
        </h3>
        <b-tabs class="col-xl-10 dataviz px-0 h4"
                justified
                no-fade
                active-nav-item-class="data-active-tab-item"
                active-tab-class="bg-white"
                v-model="tabIndex"
        >
            <b-tab :title="$t('analytics.pollenMeasurements')" :title-item-class="isMobile ? 'small':''">
                <sensor-analytics :start-date="startDate" :chart-options="barChartOptions" :stage="stage"
                                  :end-date="endDate" :loading="loading" @done="stageLoading"/>
            </b-tab>
            <b-tab :disabled="(tabIndex === 0 || tabIndex === 2) && loading"
                   :title="$t('analytics.pollenMeasurementsDetail')" :title-item-class="isMobile ? 'small':''">
                <pollen-analytics :start-date="startDate" :end-date="endDate" :loading="loading" :stage="stage"
                                  :chart-options="barChartOptions" @done="stageLoading"/>
            </b-tab>
            <b-tab :title="$t('analytics.territoryUsersData')" :title-item-class="isMobile ? 'small':''"
                   v-if="isAuthorizedForUserAnalytics">
                <user-analytics :start-date="startDate" :end-date="endDate" :loading="loading"
                                :tab-index="tabIndex" :update="update" @userDone="userLoaded"/>
            </b-tab>
        </b-tabs>
        <chart-legend/>
    </b-row>
</template>

<script>

import ChartLegend from "@/components/analytics/ChartLegend.vue";
import moment from 'moment'
import Vuex from "vuex";
import IconBase from "@/components/common/icons/IconBase.vue";
import IconCheck from "@/components/common/icons/IconCheck.vue";
import SecureLS from "@/plugins/secureLS";
import i18n from "@/i18n";
import {latLng} from "leaflet";
import "leaflet.heat";
import Multiselect from "vue-multiselect";
import geolocationService from "@/services/calcul/geolocationService";
import UserAnalytics from "@/components/analytics/UserAnalytics.vue";
import PollenAnalytics from "@/components/analytics/PollenAnalytics.vue";
import SensorAnalytics from "@/components/analytics/SensorAnalytics.vue";
import {chartTranslation} from "@/services/charts/apexchartsOptions";

moment.locale(i18n.locale)


export default {
    name: "HomeAnalytics",
    components: {
        SensorAnalytics,
        PollenAnalytics,
        UserAnalytics,
        IconCheck,
        IconBase,
        ChartLegend,
        Multiselect
    },
    data() {
        return {
            startDate: moment().set('date', 1).format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            showGlobal: true,
            pollens: true,
            accountSelected: null,
            yearSelected: null,
            accountOptions: [],
            url: `https://api.mapbox.com/styles/v1/lifyair/cl1c47qc500aq14o3vspod2w9/tiles/512/{z}/{x}/{y}@2x?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
            barChartOptions: {
                chart: {
                    type: "heatmap",
                    stacked: true,
                    locales: chartTranslation,
                    defaultLocale: i18n.locale,
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: false
                    },
                    width: "100%"
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: (date) => {
                            return moment(date).format('DD MMM');
                        }
                    },
                    y: {
                        title: {
                            formatter: (title) => {
                                return title.length === 0 || title.includes(' ') ? '' : title + ' :';
                            }
                        },
                        formatter: (val) => {
                            switch (val) {
                                case -2:
                                    return this.$t('analytics.noData');
                                case -1:
                                    return this.$t('analytics.noPollen');
                                case 0:
                                    return this.$t('risk.0') + ' ' + this.$t('risk.this');
                                case 1:
                                    return this.$t('risk.this') + ' ' + this.$t('risk.1');
                                case 2:
                                    return this.$t('risk.this') + ' ' + this.$t('risk.2');
                                case 3:
                                    return this.$t('risk.this') + ' ' + this.$t('risk.3');
                            }
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    type: 'numeric',
                    labels: {
                        formatter: (title) => {
                            if (title && title.length > 15 && !title.includes(' ')) {
                                return title.substring(0, 12) + '...';
                            }
                            return title;
                        }
                    }
                },
                noData: {
                    text: 'Aucune donnée'
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ['#ffffff']
                    }
                },
                legend: {
                    show: false
                },
                markers: {
                    size: 0
                },
                plotOptions: {
                    heatmap: {
                        radius: 0,
                        useFillColorAsStroke: false,
                        colorScale: {
                            ranges: [
                                {from: -2, to: -2, name: 'Aucune donnée', color: '#FFFFFF'},
                                {from: -1, to: -1, name: 'Aucun pollen', color: '#D5D5D5'},
                                {
                                    from: 0,
                                    to: 0,
                                    name: this.$t('risk.0') + ' ' + this.$t('risk.this'),
                                    color: '#4ba68d'
                                },
                                {
                                    from: 1,
                                    to: 1,
                                    name: this.$t('risk.this') + ' ' + this.$t('risk.1'),
                                    color: '#F6C90F'
                                },
                                {
                                    from: 2,
                                    to: 2,
                                    name: this.$t('risk.this') + ' ' + this.$t('risk.2'),
                                    color: '#FF9C5B'
                                },
                                {
                                    from: 3,
                                    to: 3,
                                    name: this.$t('risk.this') + ' ' + this.$t('risk.3'),
                                    color: '#D9534F'
                                },
                            ]
                        },
                    }
                },
                responsive: [{
                    breakpoint: 800,
                    options: {
                        plotOptions: {
                            heatmap: {
                                colorScale: {
                                    inverse: true
                                }
                            }
                        }
                    }
                }]
            },
            loading: false,
            cat: [],
            charts: [],
            buttons: [],
            yearList: [],
            selectedYear: '',
            filtered: false,
            selectedFilters: {start: '', end: ''},
            previousYear: false,
            actualYear: true,
            tabIndex: 0,
            sensorPositions: [],
            sensorCenter: null,
            center: null,
            initBounds: [],
            sensorLatitudes: [],
            sensorLongitudes: [],
            sensorMaxDistance: 25,
            latitudes: [],
            longitudes: [],
            firstDate: '',
            showUserData: false,
            update: false,
            stage: 0,
            device: '',
            orientation: '',
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics', {
            getAccountAnalytics: 'getAccountAnalytics',
        }),
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount',
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('sensor', {
            accountsSensors: 'accountsSensors',
            getSensors: 'getSensors'
        }),
        language() {
            return this.$i18n.locale
        },
        months() {
            return moment().month();
        },
        getYear() {
            return moment().format('YYYY');
        },
        isAuthorizedForTest() {
            let currentUser = SecureLS.get('currentUser');
            if (currentUser) {
                return currentUser.roles.includes('ROLE_ADMIN') ||
                    currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_TEST') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
            }
            return false;
        },
        isAuthorizedForUserAnalytics() {
            return !!(this.isAuthorizedForAccountChoice || this.currentAccount.planLevel !== "pro");
        },
        isDateValidForUserAnalytics() {
            return moment(this.startDate).format('YYYY') !== '2022';
        },
        showYearSelection() {
            return this.yearList.length > 1;
        },
        isAuthorizedForAccountChoice() {
            let currentUser = SecureLS.get('currentUser');
            if (currentUser) {
                return currentUser.roles.includes('ROLE_ADMIN') ||
                    currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
            }
            return false
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        isPortrait() {
            return this.device === 'mobile' && this.orientation !== 'landscape-primary';
        }
    },
    methods: {
        ...Vuex.mapActions('analytics', {
            setAccountAnalytics: 'setAccountAnalytics',
            setAccountUsersAnalytics: 'setAccountUsersAnalytics'
        }),
        ...Vuex.mapActions('account', {
            setCurrentAccount: 'setCurrentAccount',
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('sensor', {
            setSensorsByAccount: 'setSensorsByAccount'
        }),
        moment() {
            return moment
        },
        async delay(ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            })
        },
        setYearList() {
            this.yearList.length = 0;
            for (let year = moment(this.firstDate).format('YYYY'); year <= this.getYear; year++) {
                this.yearList.push(
                    {
                        text: this.$t('analytics.season') + ' ' + year,
                        value: year,
                        class: ''
                    }
                )
            }
        },
        setButtons(clear = false, newAccount = false) {
            let buttons = Array.from(this.buttons);
            let buttonTemp = [];
            this.buttons = [];
            let selectedY = this.yearSelected === null ? this.getYear : this.yearSelected.value;
            let day1 = moment(this.firstDate).startOf('month').format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');
            moment.months().forEach(month => {
                const date = moment().month(month).year(selectedY).startOf('month').format('YYYY-MM-DD');
                if (date >= day1 && date <= today) {
                    buttonTemp.push({
                        caption: month.length > 4 ? month.substring(0, 3).concat('.') : month,
                        value: moment().month(month).format("M"),
                        state: false,
                        class: ''
                    })
                }
            })
            if (!clear || (newAccount && parseInt(moment(this.firstDate).format('YYYY')) <= parseInt(selectedY))) {
                buttonTemp.forEach((btn) => {
                    let temp = buttons.find(button => button.value === btn.value);
                    if (this.yearSelected === null || this.getYear === moment(this.yearSelected).format('YYYY')) {
                        if (temp && btn.value === temp.value) {
                            btn.state = true;
                            btn.class = temp.class;
                        }
                    }
                })
            }
            this.buttons = buttonTemp;
        },
        getMeanValueOfArray(arr) {
            const mid = Math.floor(arr.length / 2),
                nums = [...arr].sort((a, b) => a - b);
            return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
        },
        async setSensorsPosition() {
            let sensors = this.accountsSensors;
            this.initBounds = [];
            this.sensorLatitudes = [];
            this.sensorLongitudes = [];
            this.sensorCenter = [];
            for (let i = 0; i < sensors.length; i++) {
                let sensor = sensors[i];
                if (sensor.lat && sensor.lon) {
                    this.sensorPositions.push({
                        serialNumber: sensor.serialNumber,
                        position: latLng(sensor.lat, sensor.lon),
                    })
                }
                this.initBounds.push(latLng(sensor.lat, sensor.lon))
                this.sensorLatitudes.push(sensor.lat)
                this.sensorLongitudes.push(sensor.lon)
            }

            this.initBounds.push(latLng(this.getMeanValueOfArray(this.sensorLatitudes), this.getMeanValueOfArray(this.sensorLongitudes)));
            this.sensorCenter =
                latLng(this.getMeanValueOfArray(this.sensorLatitudes), this.getMeanValueOfArray(this.sensorLongitudes))
        },
        calculateMaxDistance() {
            let distances = [];
            this.accountsSensors.forEach((sensor) => {
                if (sensor.lat && sensor.lon) {
                    distances.push(geolocationService.getDistance(
                        this.sensorCenter.lat,
                        this.sensorCenter.lng,
                        sensor.lat,
                        sensor.lon,
                    ))
                }
            })
            this.sensorMaxDistance = Math.max(...distances);
        },
        calculateDistance(lat1, lon1, lat2, lon2) {
            /**
             * Calculates the distance between two latitude-longitude points using the Haversine formula.
             */
            const earthRadiusKm = 6371.0;
            const degreesToRadians = Math.PI / 180;

            const dLat = (lat2 - lat1) * degreesToRadians;
            const dLon = (lon2 - lon1) * degreesToRadians;

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * degreesToRadians) * Math.cos(lat2 * degreesToRadians) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return earthRadiusKm * c;
        },
        async clusterSensors(sensors) {
            /**
             * Clusters sensors based on their proximity.
             * Sensors are considered in the same area if they are less than 25km away.
             * Calculates the list of serialNumbers, maximum and minimum lat, and maximum and minimum lon for each cluster.
             */
            let clusters = [];

            for (let i = 0; i < sensors.length; i++) {
                const sensor = sensors[i];
                let clusterFound = false;

                for (let j = 0; j < clusters.length; j++) {
                    let cluster = clusters[j];
                    let distance = this.calculateDistance(sensor.lat, sensor.lon, cluster.lat, cluster.lon);

                    if (distance <= 25) {
                        cluster.serialNumbers.push(sensor.serialNumber);
                        cluster.minLat = Math.min(cluster.minLat, sensor.lat);
                        cluster.maxLat = Math.max(cluster.maxLat, sensor.lat);
                        cluster.minLon = Math.min(cluster.minLon, sensor.lon);
                        cluster.maxLon = Math.max(cluster.maxLon, sensor.lon);
                        cluster.lon = (cluster.maxLon + cluster.minLon)/2;
                        cluster.lat = (cluster.maxLat + cluster.minLat)/2;
                        clusterFound = true;
                        break;
                    }
                }

                if (!clusterFound) {
                    clusters.push({
                        serialNumbers: [sensor.serialNumber],
                        minLat: sensor.lat,
                        maxLat: sensor.lat,
                        lat: sensor.lat,
                        lon: sensor.lon,
                        minLon: sensor.lon,
                        maxLon: sensor.lon
                    });
                }
            }

            return clusters;
        },
        async loadUserAnalytics(account = this.currentAccount.name) {
            let clusters = await this.clusterSensors(this.accountsSensors.filter(item => item.status === "prod"))

            await this.setAccountUsersAnalytics({
                    accounts: account,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    lat: this.sensorCenter.lat,
                    long: this.sensorCenter.lng,
                    clusters: clusters,
                    distance: 25,
                    sensors: this.accountsSensors.map(item => item.dataReference)
                }
            )
            this.update = true;
        },
        async loadAccountAnalytics(
            account = this.currentAccount.name,
            startDate = this.startDate,
            endDate = this.endDate,
            sensorCenter = this.sensorCenter,
            distance = this.sensorMaxDistance + 25,
            sensors = this.accountsSensors.map(item => item.dataReference)
        ) {
            await this.setAccountAnalytics({
                accounts: account,
                startDate: startDate,
                endDate: endDate,
                lat: sensorCenter.lat,
                long: sensorCenter.lng,
                distance: distance,
                sensors : sensors
            })
        },
        stageLoading() {
            if (this.stage === 2) {
                this.stage = 0;
                this.loading = false;
            } else {
                this.stage++;
            }
        },
        userLoaded() {
            this.update = false;
        },
        selectYear() {
            if (this.yearSelected === null) {
                this.selectedYear = '';
                this.filtered = false;
                this.buttons.forEach(btn => {
                    btn.state = false
                    btn.class = '';
                })
                return;
            }
            this.selectedYear = '';
            this.filtered = false;
            this.buttons = [];
            this.selectedYear = 'chosen'
            if (this.yearSelected.value.toString() === this.getYear) {
                this.actualYear = true;
                this.previousYear = false;
                this.selectedFilters.start = moment().startOf('year').format('YYYY-MM-DD');
                this.selectedFilters.end = moment().format('YYYY-MM-DD')
            } else {
                this.actualYear = false;
                this.previousYear = true;
                this.selectedFilters.start = moment().set('year', this.yearSelected.value).startOf('year').format('YYYY-MM-DD');
                this.selectedFilters.end = moment().set('year', this.yearSelected.value).endOf('year').format('YYYY-MM-DD')
            }
            this.setButtons();
        },
        selectFilters(button) {
            if (this.loading) {
                return;
            }
            this.filtered = false;
            this.buttons.forEach(btn => {
                if (this.selectedFilters.start.length !== 0 && moment(this.selectedFilters.start).format('MM') !== moment(this.selectedFilters.end).format('MM')) {
                    btn.value !== button.value ? (btn.state = false, btn.class = '') : (btn.state = true, btn.class = 'chosen')
                }
            })
            let selectedBtns = [];
            this.buttons.forEach(btn => {
                if (btn.state) {
                    selectedBtns.push(btn.value)
                }
            });
            let sBtnMax = Math.max(...selectedBtns);
            let sBtnMin = Math.min(...selectedBtns);

            let selectedY = this.yearSelected === null ? this.getYear : this.yearSelected.value;

            this.buttons.forEach(btn => {
                btn.state = btn.value >= sBtnMin && btn.value <= sBtnMax;
                btn.value >= sBtnMin && btn.value <= sBtnMax ? btn.class = 'chosen' : '';
            })

            if (selectedY === this.getYear && selectedBtns.length === 0) {
                this.selectedFilters.start = moment().startOf('month').set('month', 0).format('YYYY-MM-DD');
                this.selectedFilters.end = moment().format('YYYY-MM-DD');
            } else if (selectedY === this.getYear && selectedBtns.length > 0) {
                this.selectedFilters.start = moment().set('month', sBtnMin - 1).startOf('month').format('YYYY-MM-DD');
                this.selectedFilters.end = moment().set('month', sBtnMax - 1).endOf('month').format('YYYY-MM-DD');
            } else if (selectedY !== this.getYear && selectedBtns.length === 0) {
                this.selectedFilters.start = moment().year(selectedY).startOf('year').format('YYYY-MM-DD');
                this.selectedFilters.end = moment().year(selectedY).endOf('year').format('YYYY-MM-DD');
            } else if (selectedY !== this.getYear && selectedBtns.length > 0) {
                this.selectedFilters.start = moment().year(selectedY).set('month', sBtnMin - 1).startOf('month').format('YYYY-MM-DD');
                this.selectedFilters.end = moment().year(selectedY).set('month', sBtnMax - 1).endOf('month').format('YYYY-MM-DD');
            } else {
                this.selectedFilters.start = '';
                this.selectedFilters.end = '';
            }
        },
        async submit(newAccount = false) {
            this.loading = true;
            let account = [];
            let accountGroups = [];
            if (this.accountSelected) {
                account = this.accounts.find(i => i.id === this.accountSelected.value);
            } else {
                account = this.currentAccount;
            }

            account.accountGroups.forEach(i => {
                accountGroups.push(i.id);
            })

            if (this.accountsSensors.length === 0 || newAccount) {
                await this.setSensorsByAccount({params: {accounts: accountGroups}});
            }

            await this.setSensorsPosition();
            this.calculateMaxDistance();

            if (this.selectedFilters.start && this.selectedFilters.end) {
                this.startDate = this.firstDate > this.selectedFilters.start ? this.firstDate : this.selectedFilters.start;
                this.endDate = this.selectedFilters.end <= moment().format('YYYY-MM-DD') ? this.selectedFilters.end : moment().format('YYYY-MM-DD');
            }
            if (this.startDate < this.firstDate) {
                this.startDate = this.firstDate;
            }
            if (this.selectedYear === 'chosen') {
                this.selectedYear = 'applied';
            }

            await this.loadAccountAnalytics(
                account && this.isAuthorizedForAccountChoice ? account.name : this.currentAccount.name
            ).then(() => {
                this.firstDate = moment(this.getAccountAnalytics.startDate);
            });

            let selectedY = this.yearSelected === null ? this.getYear : this.yearSelected.value
            if (!newAccount) {
                this.buttons.forEach(btn => {
                    btn.state === true ? btn.class = 'applied' : '';
                })
                this.yearList.forEach(btn => {
                    btn.class === 'chosen' ? btn.class = 'applied' : '';
                })
            }

            if (this.firstDate > this.startDate) {
                this.selectedFilters.start = '';
                this.selectedFilters.end = '';
                this.startDate = moment().set('date', 1).format('YYYY-MM-DD');
                this.endDate = moment().format('YYYY-MM-DD');
                this.selectedYear = '';
                this.yearSelected = null;
                await this.loadAccountAnalytics(account && this.isAuthorizedForAccountChoice ? account.name : this.currentAccount.name);
            }
            await this.loadUserAnalytics(
                account && this.isAuthorizedForAccountChoice ? account.name : this.currentAccount.name
            );


            if (newAccount && parseInt(moment(this.firstDate).format('YYYY')) <= parseInt(selectedY)) {
                this.setButtons(false, true);
                this.setYearList();
            } else if (newAccount && parseInt(moment(this.firstDate).format('YYYY')) > parseInt(selectedY)) {
                this.yearSelected = null;
                this.yearList.forEach(btn => {
                    btn.class = ''
                })
                this.setButtons(true);
                this.setYearList();
            }
            this.filtered = true;
            this.stageLoading();
            await this.delay(200);
        },
        async clearFilter() {
            this.loading = true;
            this.yearList.forEach(btn => {
                btn.state = false;
                btn.class = '';
            })
            this.buttons.forEach(btn => {
                btn.state = false
                btn.class = '';
            })

            this.yearSelected = null;

            let accountGroups = [];
            let account = this.currentAccount;


            account.accountGroups.forEach(i => {
                accountGroups.push(i.id);
            })

            await this.setSensorsByAccount({params: {accounts: accountGroups}});

            await this.setSensorsPosition();

            if (moment().get('date') < 10) {
                this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
            } else {
                this.startDate = moment().set('date', 1).format('YYYY-MM-DD');
            }
            this.endDate = moment().format('YYYY-MM-DD');
            this.filtered = false;
            this.accountSelected = null
            await this.loadUserAnalytics();

            this.update = true;
            await this.delay(250);
            await this.loadAccountAnalytics()
                .then(() => {
                    this.firstDate = moment(this.getAccountAnalytics.startDate);
                    this.setButtons(true);
                    this.setYearList(true);
                    this.stageLoading();
                });
        },
        handleOrientationChange() {
            if (window.screen.orientation.type === "portrait-primary") {
                this.orientation = window.screen.orientation.type;
            } else if (window.screen.orientation.type === "landscape-primary" || window.screen.orientation.type === "landscape-secondary") {
                this.orientation = window.screen.orientation.type
            }
        }
    },
    async created() {
        moment.locale(i18n.locale)
        this.loading = true;

        // if (!this.isAuthorizedForTest) {
        //     await this.$router.push('/');
        // }

        if (this.isAuthorizedForAccountChoice) {
            await this.setAccounts({paginated: false});
            this.accounts.forEach(item => {
                this.accountOptions.push({text: item.name, value: item.id});
            })

            this.accountOptions.sort((a, b) => {
                let textA = a.text;
                let textB = b.text;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        if (moment().get('date') < 10) {
            this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
        }

        await this.setCurrentAccount();

        if (this.isAuthorizedForAccountChoice) {
            let accountGroups = [];
            let accounts = this.accountSelected ? this.accountSelected : this.currentAccount;
            accounts.accountGroups.forEach(i => {
                accountGroups.push(i.id);
            })
            let params = {
                params: {accounts: accountGroups}
            }

            await this.setSensorsByAccount(params);
        } else {
            await this.setSensorsByAccount();
        }

        await this.setSensorsPosition();

        await this.loadAccountAnalytics(this.currentAccount.name)

        await this.loadUserAnalytics().then(() => {
            this.firstDate = moment(this.getAccountAnalytics.startDate);
            this.setYearList();
            this.setButtons();
        })

        this.stageLoading();
    },
    mounted() {
        window.addEventListener(
            "orientationchange",
            this.handleOrientationChange
        );


        if (window.screen.orientation.type === "portrait-primary") {
            this.orientation = window.screen.orientation.type;
        } else if (window.screen.orientation.type === "landscape-primary" || window.screen.orientation.type === "landscape-secondary") {
            this.orientation = window.screen.orientation.type
        }

        if (this.isMobile) {
            this.device = 'mobile';
        } else {
            this.device = 'browser';
        }
    },
    watch: {
        yearSelected: function () {
            this.selectYear();
        },
        accountSelected: function (newVal, oldVal) {
            if (newVal !== null && oldVal !== newVal) {
                this.submit(true)
            }
        },
        sensorCenter: function (newVal) {
            if (newVal.lat && newVal.lng) {
                this.calculateMaxDistance();
            }
        },
    },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
<style lang="scss">

.multiselect {
    border: 0;
    border-radius: 4px;

    .multiselect__select::before {
        top: 60%;
    }

    .multiselect__placeholder {
        padding: 0 7px;
        color: $dimgrey;
        font-size: 14px;
        margin: 0;
    }

    .multiselect__content-wrapper, .multiselect__tags {
        border-color: $lightslategrey;
    }

    .multiselect__single {
        color: $orange-lifyair;
    }

    .multiselect__option {
        background-color: $white-whitesmoke;
        color: $darkslategrey;
    }

    .multiselect__option--group {
        background-color: $white;
        color: $darkslategrey;
        font-size: 14px;
    }

    .multiselect__tag-icon {
        background-color: $orange-lifyair;

        &::after {
            color: $white;
        }
    }

    .multiselect__tag {
        display: none;
        color: $white;
        background-color: $orange-lifyair;

        &.sensor {
            overflow-wrap: break-word;
            padding-right: 10px;
        }
    }


    .multiselect__tags {
        padding: 10px 40px 0 8px;
    }

    &.chosen {

        .multiselect__tags {
            border: 1px solid $orange-lifyair;
        }

        /*.multiselect__select::before {
            color: $orange-lifyair;
            border-color: $orange-lifyair transparent transparent;
        }*/

        .multiselect__placeholder {
            color: $orange-lifyair;
        }
    }

    &.applied {
        .multiselect__tags {
            border: 1px solid $orange-lifyair;
        }

        .multiselect__placeholder {
            color: $orange-lifyair;
        }

        .multiselect__tags {
            background-color: #FFEFE5;
        }

        .multiselect__single {
            background-color: #FFEFE5;
            margin: 0;
        }
    }

    &.disable {
        .multiselect__tags {
            border: 1px solid $lightgrey;
        }

        .multiselect__select::before {
            color: $lightgrey;
            border-color: $lightgrey transparent transparent;
        }

        .multiselect__placeholder {
            color: $lightgrey;
        }

        .multiselect__tags {
            background-color: $white-whitesmoke;
        }

        .multiselect__single {
            background-color: $white-whitesmoke;
            margin: 0;
        }
    }

    .multiselect__option {
        background-color: $white;
    }

    .multiselect__option--highlight {
        background-color: $white-whitesmoke;
        color: $dimgrey;
    }

    .multiselect__option--selected {
        color: $white;
        background-color: $orange-lifyair;
    }

    &.multiselect--disabled {

        .multiselect__select {
            background: none;
        }

        .multiselect__select::before {
            color: $lightgrey;
            //border-color: $lightgrey transparent transparent;
        }

        .multiselect__placeholder {
            color: $lightgrey;
        }

        .multiselect__tags {
            background-color: $white-whitesmoke;
            border-color: $lightgrey;
        }

        .multiselect__single {
            background-color: $white-whitesmoke;
            margin: 0;
        }
    }
}

#heatMap {
    width: 100%;
    aspect-ratio: 16 / 9;
    /*
        .leaflet-top {
            display: none !important;
        }*/
    .leaflet-top {
        display: none !important;
    }

    .leaflet-control-zoom .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        border-width: 0;
    }
}

.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
}

.month {
    font-size: .9rem;
    height: 25px;
}

.analyticsFilter {
    > div {
        padding: 5px 0;
    }
}

.monthList {
    display: flex;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }

}

.filters {
    display: flex;
    justify-content: space-around;
}

.btn-live-pollen-filter {
    background-color: rgba(255, 255, 255, 0);
    color: #636d73;
    border-radius: 5px;
    border: 1px solid #e8e8e8 !important;
    font-family: 'Myriad Pro Semibold', sans-serif;
    font-size: 14px;
    text-transform: uppercase;

    &.secondary {
        background-color: transparent;
        color: $orange-lifyair;
        text-decoration: underline;
        border: 1px solid $orange-lifyair !important;
    }

    &.chosen {
        border-color: $orange-lifyair;
        color: $orange-lifyair;
        border: 1px solid $orange-lifyair !important;
    }

    &.applied {
        background-color: #FEEFE5;
        border-color: $orange-lifyair;
        color: $orange-lifyair;
        border: 0.2em solid $orange-lifyair !important;
    }

    &.outlined {
        border: 1px solid $orange-lifyair !important;
        background-color: transparent;
        color: $orange-lifyair;
    }

    &:disabled {
        background-color: #D5D5D5;
        color: $lightslategrey;
    }
}

.nav-item {
    min-width: 200px;
    @media screen and (min-width: 1000px) {
        margin: 0 4px;
        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.active-tab-item {
    background-color: #FFF;
}

.active-tab {
    background-color: #FFF;
}

.apexcharts-toolbar {
    z-index: 0 !important;
}

.pollen-square-detail {
    padding: 1px 5px;
    margin: 1px 5px;
}

.landscapeContainer {
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
}

.message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    /* display: none; */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(-90deg)
    }
    100% {
        transform: rotate(-90deg)
    }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
    .phone, .message {
        display: block;
    }
}
</style>
