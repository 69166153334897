<template>
    <b-row align-v="start" align-h="around">
        <b-col cols="12" md="3">
            <b-row>
                <b-col cols="12">
                    <p class="widget-title">{{ $t('common.title.popup.riskForSpecies') | capitalize }}</p>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <p class="widget-description">{{ $t('common.title.popup.riskForSpeciesDetail') | capitalize }}</p>
                </b-col>
            </b-row>
        </b-col>
        <b-row>
            <b-col cols="12" md="4" v-if="isBanner">
                <b-row>
                    <b-col cols="12" class="text-center">
                        <IconBase v-if="selectedRollingRisk.riskLevel === 0 || selectedRollingRisk.riskLevel === null"
                                  view-box="0 0 90 57" width="100" height="100">
                            <IconNone/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 1" view-box="0 0 90 90" width="100"
                                  height="100">
                            <IconLow/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 2" view-box="0 0 90 90" width="100"
                                  height="100">
                            <IconMedium/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 3" view-box="0 0 470 447" width="100"
                                  height="100">
                            <IconHigh/>
                        </IconBase>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="7" align-self="center">
                <b-row align-v="center">
                    <b-col cols="12" class="text-center text-md-left">
                        <p class="risk-badge-title">{{ $t('common.title.popup.expositionRisk') | capitalize }}</p>
                    </b-col>
                    <b-col cols="7" md="12" class="mx-auto">
                        <div class="risk-badge" :class="`bg-${riskColor}`">
                            <p :class="clrByRisk" class="mb-1">{{
                                    $t(`risk.${selectedRollingRisk.riskLevel}`) | capitalize
                                }}</p>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="4" v-if="!isBanner">
                <b-row>
                    <b-col cols="12" class="text-center">
                        <IconBase v-if="selectedRollingRisk.riskLevel === 0 || selectedRollingRisk.riskLevel === null"
                                  view-box="0 0 90 57" width="100" height="100">
                            <IconNone/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 1" view-box="0 0 90 90" width="100"
                                  height="100">
                            <IconLow/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 2" view-box="0 0 90 90" width="100"
                                  height="100">
                            <IconMedium/>
                        </IconBase>
                        <IconBase v-else-if="selectedRollingRisk.riskLevel === 3" view-box="0 0 470 447" width="100"
                                  height="100">
                            <IconHigh/>
                        </IconBase>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-col cols="12" md="4">
            <b-row>
                <b-col cols="12" class="mt-2">
                    <b-row align-v="center" class="about-card no-gutters justify-content-around">
                        <b-col cols="1" align-self="center">
                            <IconBase icon-name="question-mark" viewBox="0 0 21 20" height="25" width="25">
                                <IconQuestionMark/>
                            </IconBase>
                        </b-col>
                        <b-col cols="10">
                            <p class="widget-description">{{
                                    $t('common.title.popup.aboutExpositionRisk') | capitalize
                                }}</p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex';
import IconBase from "@/components/common/icons/IconBase";
import IconQuestionMark from "@/components/common/icons/IconQuestionMark";
import IconNone from "@/components/common/icons/riskExposure/IconNone";
import IconLow from "@/components/common/icons/riskExposure/IconLow";
import IconMedium from "@/components/common/icons/riskExposure/IconMedium";
import IconHigh from "@/components/common/icons/riskExposure/IconHigh";
import pollenService from "@/services/pollen/pollenService";

export default {
    name: "WidgetPollenDetail",
    components: {IconHigh, IconMedium, IconLow, IconNone, IconQuestionMark, IconBase},
    props: {
        isBanner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...Vuex.mapGetters('rollingRisk', {
            selectedRollingRisk: 'selectedRollingRisk'
        }),
        clrByRisk() {
            return (this.selectedRollingRisk.riskLevel === 3 || this.selectedRollingRisk.riskLevel === 0) ? 'clr-white' : 'clr-darkslategrey'
        },
        riskColor() {
            return pollenService.getColorByValue(this.selectedRollingRisk.riskLevel);
        },
    },
}
</script>

<style lang="scss" scoped>
.risk-badge-title {
    color: #979797;
    font-size: 1rem;
    margin: 0;
}

.risk-badge {
    border-radius: 44px;
    text-align: center;
}

.about-card {
    border: 1px solid #D5D5D5;
    padding: 5px;
    margin: 0;
}
</style>
