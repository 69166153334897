<template>
    <div v-if="!userAnalyticsLoaded"
         class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center">
        <div class="d-flex w-100 align-items-center justify-content-center"
             style="height: 300px">
            <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
        </div>
    </div>
    <div
        class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center"
        v-else-if="mixedChartSeries.length === 0 && userAnalyticsLoaded">
        <b-col class="col-12 mt-3">
            <h3 class="container-title mb-0 text-center my-5">
                {{ $t('analytics.noData') }}
            </h3>
        </b-col>
    </div>
    <div
        class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center"
        v-else>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.reactionByConnection.title') }}</p>
            <p class="h6">{{ $t('analytics.reactionByConnection.subTitle') }}</p>
            <apexchart :options="mixedChartOptions" :series="mixedChartSeries" :height="300"
                       v-if="mixedChartOptionsL && mixedChartSeries.length > 0 && isDateValidForUserAnalytics"></apexchart>
            <div
                v-else-if="!mixedChartOptionsL && isDateValidForUserAnalytics"
                class="w-100 d-flex align-items-center justify-content-center"
                style="height: 300px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
            <b-row class="col-7 flex-nowrap" v-if="!isDateValidForUserAnalytics">
                <b-col cols="5" md="4" class="m-0">
                    <IconBase viewBox="0 0 78 67" width="100%" height="100%">
                        <IconData/>
                    </IconBase>
                </b-col>
                <b-col cols="12" md="8"
                       class="m-0 ml-md-3 my-2 pl-0 d-flex align-items-center pollen-square-detail">
                    <p class="clr-lightslategrey m-0 h4">
                        {{ $t('emptyState.noUserReportsYet') }}
                    </p>
                </b-col>
            </b-row>
        </b-col>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.notificationSetup.title') }}</p>
            <p class="h6">{{ $t('analytics.notificationSetup.subTitle') }}</p>
            <apexchart :options="lineChartOptions" :series="totalNotificationSeries" :height="300"
                       v-if="dailyNotificationL && totalNotificationSeries.length > 0"></apexchart>
            <div v-else class="d-flex w-100 align-items-center justify-content-center"
                 style="height: 300px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
        </b-col>
        <b-col class="col-12 mt-5">
            <p class="container-title mb-0">{{ $t('analytics.mapReaction.title') }}</p>
            <p class="h6">{{ $t('analytics.mapReaction.subTitle') }}</p>
            <div class="col-12" id="heatMap">
                <l-map v-if="tabIndex === 2"
                       :center="center"
                       style="z-index: 0;"
                       :zoom="zoom"
                       :max-zoom="maxZoom"
                       :min-zoom="isAuthorizedForAccountChoice ? 6 : minZoom"
                       ref="heatMap"
                       id="heatMap2"
                       :options="{zoomControl: false}"
                       @ready="setMapLoaded"
                >
                    <l-tile-layer :url="url"/>
                    <Vue2LeafletHeatMap v-if="mapLoaded" :lat-lng="latLng" :radius="35" :min-opacity=".55"
                                        :max-zoom="maxZoom"
                                        :blur="50"></Vue2LeafletHeatMap>
                    <l-control-zoom position="bottomright"></l-control-zoom>
                </l-map>
            </div>
        </b-col>
    </div>
</template>
<script>
import Vuex from "vuex";
import Vue2LeafletHeatMap from "@/components/analytics/Vue2LeafletHeatMap.vue";
import {latLng} from "leaflet";
import "leaflet.heat";
import geolocationService from "@/services/calcul/geolocationService";
import moment from "moment/moment";
import {LControlZoom, LMap, LTileLayer} from "vue2-leaflet";
import IconBase from "@/components/common/icons/IconBase.vue";
import IconData from "@/components/common/icons/IconData.vue";
import {chartTranslation} from "@/services/charts/apexchartsOptions";
import i18n from "@/i18n";
import SecureLS from "@/plugins/secureLS";

export default {
    components: {IconData, IconBase, Vue2LeafletHeatMap, LMap, LTileLayer, LControlZoom},
    props: {
        startDate: String,
        endDate: String,
        update: Boolean,
        loading: Boolean,
        tabIndex: Number
    },
    data() {
        return {
            url: `https://api.mapbox.com/styles/v1/lifyair/cl1c47qc500aq14o3vspod2w9/tiles/512/{z}/{x}/{y}@2x?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
            userAnalyticsLoaded: false,
            totalNotificationSeries: [],
            mixedChartSeries: [],
            mixedChartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    locales: chartTranslation,
                    defaultLocale: i18n.locale,
                    zoom: {
                        enabled: false
                    },
                },
                stroke: {
                    curve: 'smooth',
                },
                fill: {
                    type: 'solid',
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    tooltip: {
                        enabled: false
                    }
                },
                labels: [],
                colors: ['#ff5e00', 'rgba(255,94,0,0.3)']

            },
            lineChartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    locales: chartTranslation,
                    defaultLocale: i18n.locale,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            show: false
                        },
                    },
                },
                colors: ['#FF5A00'],
                legend: {
                    show: false
                },
                noData: {
                    text: 'Aucune donnée'
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    tooltip: {
                        enabled: false
                    }
                },
            },
            dailyNotificationL: false,
            mapReaction: false,
            mixedChartOptionsL: false,
            mapLoaded: false,
            center: null,
            sensorMaxDistance: 25,
            zoom: 11,
            minZoom: 9.5,
            maxZoom: 15,
            sensorCenter: [],
            sensorPositions: [],
            latitudes: [],
            longitudes: [],
            sensorLatitudes: [],
            sensorLongitudes: [],
            latLng: [],
            days: [],
            initBounds: []
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics', {
            getAccountUsersAnalytics: 'getAccountUsersAnalytics'
        }),
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount',
        }),
        ...Vuex.mapGetters('sensor', {
            accountsSensors: 'accountsSensors',
        }),
        isDateValidForUserAnalytics() {
            return moment(this.startDate).format('YYYY') !== '2022';
        },
        isAuthorizedForAccountChoice() {
            let currentUser = SecureLS.get('currentUser');
            if (currentUser) {
                return currentUser.roles.includes('ROLE_ADMIN') ||
                    currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
            }
            return false
        },
        getMaxZoom() {
            return this.maxZoom;
        },
        dDiff() {
            return moment(this.endDate).diff(this.startDate, "days")
        },
        getMinZoom() {
            return this.minZoom;
        }
    },
    methods: {
        ...Vuex.mapActions('analytics', {
            setAccountUsersAnalytics: 'setAccountUsersAnalytics'
        }),
        async delay(ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            })
        },
        async setMapLoaded() {
            if (this.latLng.length > 0 && this.$refs.heatMap.mapObject) {
                this.mapLoaded = true;
            }
        },
        setDays() {
            this.days = [];
            for (let i = 0; i < this.dDiff + 1; i++) {
                this.days.push({x: moment(this.startDate).add(i, "day").format('YYYY-MM-DD'), y: -2})
            }
        },
        getMeanValueOfArray(arr) {
            const mid = Math.floor(arr.length / 2),
                nums = [...arr].sort((a, b) => a - b);
            return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
        },
        calculateMaxDistance() {
            let distances = [];
            this.accountsSensors.forEach((sensor) => {
                distances.push(geolocationService.getDistance(
                    this.sensorCenter.lat,
                    this.sensorCenter.lng,
                    sensor.lat,
                    sensor.lon,
                ))
            })
            this.sensorMaxDistance = Math.max(...distances);
        },
        async setSensorsPosition() {
            let sensors = this.accountsSensors;
            this.initBounds = [];
            this.sensorLatitudes = [];
            this.sensorLongitudes = [];
            this.sensorCenter = [];
            for (let i = 0; i < sensors.length; i++) {
                let sensor = sensors[i];
                if (sensor.lat && sensor.lon) {
                    this.sensorPositions.push({
                        serialNumber: sensor.serialNumber,
                        position: latLng(sensor.lat, sensor.lon),
                    })
                }
                this.initBounds.push(latLng(sensor.lat, sensor.lon))
                this.sensorLatitudes.push(sensor.lat)
                this.sensorLongitudes.push(sensor.lon)
            }

            this.initBounds.push(latLng(this.getMeanValueOfArray(this.sensorLatitudes), this.getMeanValueOfArray(this.sensorLongitudes)));
            this.sensorCenter =
                latLng(this.getMeanValueOfArray(this.sensorLatitudes), this.getMeanValueOfArray(this.sensorLongitudes))
        },
        async setTotalNotificationSetup() {
            this.totalNotificationSeries = [];
            let d = this.getAccountUsersAnalytics.dailyNotificationSetup;
            const data = JSON.parse(JSON.stringify(this.days));
            let t = {name: '', data: data};
            let count = d[1][0].total;
            t.data.forEach((item) => {
                if (d[0].length)
                    d[0].find((date) => {
                        if (moment(date.createdAt).format('YYYY-MM-DD') === item.x) {
                            count += date.totalCount;
                            return item.y = count;
                        }
                    })
                return item.y = count;
            })
            this.totalNotificationSeries.push(t);
        },
        async setMixedChart() {
            this.mixedChartSeries = [];
            this.mixedChartOptions.labels = [];
            let dailyConnections = this.getAccountUsersAnalytics.dailyConnectionByDate;
            let dailyReports = this.getAccountUsersAnalytics.dailyReportsByDate;
            this.days.map(d => {
                this.mixedChartOptions.labels.push(d.x);
            })
            const area = {
                name: this.$t('analytics.sensorConnection.title'),
                type: 'area',
                data: [],
            }
            const line = {
                name: this.$t('form.field.userReport'),
                type: 'line',
                data: []
            };
            const temp = {name: '', data: this.days};
            if (dailyReports.length === 0) {
                temp.data.forEach((i) => {
                    i.y = 0;
                })
            }
            for (let report in dailyReports) {
                temp.data.find((item) => {
                    if (item.y < 0) item.y = 0;
                    if (item.x === moment(dailyReports[report].createdAt).format('YYYY-MM-DD')) {
                        item.y++;
                    }
                })
            }
            temp.data.forEach(d => {
                line.data.push(d.y);
            })

            temp.data.forEach(d => d.y = 0);
            for (let connection in dailyConnections) {
                temp.data.find((item) => {
                    if (item.x === moment(dailyConnections[connection].createdAt).format('YYYY-MM-DD')) {
                        item.y = dailyConnections[connection].total;
                    }
                })
            }
            temp.data.forEach(d => {
                area.data.push(d.y)
            })

            this.mixedChartSeries.push(line, area);

        },
        async setMapReaction() {
            this.latLng = [];
            let d = this.getAccountUsersAnalytics.dailyReportsByDate;
            if (d.length > 0) {
                this.latitudes = [];
                this.longitudes = [];
                this.center = [];
                this.latitudes = d.map((p) => {
                    return p.lat
                });
                this.longitudes = d.map((p) => {
                    return p.lng
                });
                this.center =
                    latLng(this.getMeanValueOfArray(this.latitudes), this.getMeanValueOfArray(this.longitudes))
                this.latLng = d.map(function (p) {
                    return [p.lat + (Math.random() * (0.002 - 0.001)), p.lng + (Math.random() * (0.002 - 0.001)), 1]
                });
            }
        },
        setLoading() {
            this.mixedChartOptionsL = false;
            this.dailyNotificationL = false;
            this.userAnalyticsLoaded = false;
            this.mapReaction = false;
        },
        async render() {
            this.setDays();
            await this.setSensorsPosition();
            await this.setMixedChart();
            this.mixedChartOptionsL = true;
            await this.delay(250)

            await this.setTotalNotificationSetup();
            this.dailyNotificationL = true;
            await this.delay(250)

            await this.setMapReaction();
            this.mapReaction = true;
            this.userAnalyticsLoaded = true;

            this.$emit('userDone');
        }
    },
    async created() {
        this.setDays();
    },
    watch: {
        sensorCenter: function (newVal) {
            if (newVal.lat && newVal.lng) {
                this.calculateMaxDistance();
            }
        },
        loading: async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.setLoading();
            }
        },
        update: async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                await this.render();
            }
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
<style lang="scss">

#heatMap {
    width: 100%;
    aspect-ratio: 16 / 9;
    /*
        .leaflet-top {
            display: none !important;
        }*/
    .leaflet-top {
        display: none !important;
    }

    .leaflet-control-zoom .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        border-width: 0;
    }
}

.apexcharts-toolbar {
    z-index: 0 !important;
}

.pollen-square-detail {
    padding: 1px 5px;
    margin: 1px 5px;
}
</style>
