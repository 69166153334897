<template>
    <div
        class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center">
        <div
            class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center"
            v-if="riskByPollen.length === 0 && !loadingPollen">
            <b-col class="col-12 mt-3">
                <h3 class="container-title mb-0 text-center my-5">
                    {{ $t('analytics.noData') }}
                </h3>
            </b-col>
        </div>
        <div
            class="d-flex col-xl-12 py-2 px-1 d-flex flex-column align-content-center justify-content-xl-between justify-content-center"
            v-else-if="loadingPollen">
            <b-col class="col-12 mt-3">
                <h3 class="container-title mb-0 text-center my-5">
                    <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
                </h3>
            </b-col>
        </div>
        <b-col v-else class="col-12 my-2">
            <multiselect v-model="pollenSelected" :options="options" :show-labels="false" label="text">
                <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.text }}</strong></template>
            </multiselect>
            <p class="container-title mb-0 mt-3">{{ pollenSelected.text }}</p>
            <p class="h6 mb-0">{{ $t('analytics.pollensList') }}</p>
            <apexchart ref="chart" :options="chartOptions" :series="pollenRiskYearly" :height="300"
                       v-if="pollenRiskYearly.length > 0"></apexchart>
            <div v-else class="d-flex w-100 align-items-center justify-content-center"
                 style="height: 300px">
                <b-spinner style="color: #FF5A00" label="Spinning"></b-spinner>
            </div>
        </b-col>
    </div>
</template>

<script>

import Vuex from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    name: "PollenAnalytics",
    components: {Multiselect},
    props: {
        startDate: String,
        endDate: String,
        update: Boolean,
        loading: Boolean,
        chartOptions: Object,
        stage: Number
    },
    data() {
        return {
            pollenRiskYearly: [],
            pollenRiskYearlyChart: [],
            loadingPollen: false,
            days: [],
            pollenSelected: null,
            options: [],
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics', {
            getAccountAnalytics: 'getAccountAnalytics',
        }),
        riskByPollen() {
            return this.pollenRiskYearly
        },
        mDiff() {
            return moment(this.endDate).diff(this.startDate, "month");
        },
        dDiff() {
            return moment(this.endDate).diff(this.startDate, "days")
        }
    },
    methods: {
        async delay(ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            })
        },
        async setDays() {
            this.days = [];
            for (let i = 0; i < this.dDiff + 1; i++) {
                this.days.push({x: moment(this.startDate).add(i, "day").format('YYYY-MM-DD'), y: -2})
            }
        },
        async setOptionsList() {
            let data = this.getAccountAnalytics.highestRiskPollen;
            let pollens = Object.keys(data);
            let unOrderedPollens = [];
            pollens.forEach(pollen => {
                unOrderedPollens.push({
                    text: this.$t('pollen.type.' + pollen),
                    value: pollen
                });
            })
            this.options = unOrderedPollens.sort(function (a, b) {
                if (a.text < b.text) return -1;
                if (a.text > b.text) return 1;
                return 0;
            });
        },
        async setYearlyPollenRisk(newPollen = false) {
            this.pollenRiskYearly.length = 0;
            if (!this.pollenSelected) {
                this.loadingPollen = false
                this.$emit('done');
                return
            }
            let d = this.getAccountAnalytics.highestRiskPollen[this.pollenSelected.value];
            if (d === null || d === undefined) {
                this.loadingPollen = false
                return
            }

            let sensorList = [];
            d.forEach(pollen => {
                if (sensorList.length === 0) {
                    sensorList.push(pollen[2]);
                } else if (!sensorList.find(sensor => sensor === pollen[2])) {
                    sensorList.push(pollen[2]);
                }
            })
            sensorList.forEach(sensor => {
                const data = JSON.parse(JSON.stringify(this.days));
                let item = {name: sensor, data: data};
                d.forEach(pollen => {
                    item.data.forEach(series => {
                        if (pollen[2] === sensor && pollen[0] === series.x) {
                            pollen[1] === null ? series.y = -1 : series.y = pollen[1];
                        }
                    })
                })
                this.pollenRiskYearly.push(item);
            })
            this.loadingPollen = false

            if (!newPollen) {
                this.$emit('done');
            }
        },

    },
    async created() {
        await this.setDays();
    },
    watch: {
        loading: async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.pollenRiskYearly = [];
                this.loadingPollen = true;
            }
        },
        stage: async function (newVal) {
            if (newVal === 2) {
                await this.setDays();
                await this.setOptionsList();
                this.pollenSelected = this.options[0];
                await this.setYearlyPollenRisk();
            }
        },
        pollenSelected: async function (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                await this.setYearlyPollenRisk(true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
