<template>
    <b-container fluid class="min-vh-100">
        <b-dropdown id="language" variant="link" toggle-class="text-decoration-none" right no-caret
                    :text="$t(`common.language.${$i18n.locale}`)">
            <b-dropdown-item
                v-for="(lang, index) in languages"
                :key="index"
                @click="$i18n.locale = lang.value">
                {{ lang.text }}
            </b-dropdown-item>
        </b-dropdown>
        <b-row align-h="end" class="mt-1" v-if="authStatus.message">
            <div class="col-auto">
                {{ authStatus.message }}
            </div>
        </b-row>
        <b-row align-h="center" align-v="center" class="vh-100">
            <b-col class="m-0 mt-md-5 login-card">
                <b-row class="mt-5">
                    <b-col class="text-center mt-5">
                        <img src="@/assets/images/icon/logo_header.svg" width="160"/>
                    </b-col>
                </b-row>
                <b-row align-h="center" class="mb-5">
                    <b-col cols="10" md="9">
                        <div class="my-5 big-title" v-if="!firstConnexion">
                            {{
                                $t(showForgotPassword ? 'common.title.forgotPassword' : 'common.title.login') | capitalize
                            }}
                        </div>
                        <div class="my-5 big-title" v-else>
                            {{ $t('common.title.firstConnexion') }}
                        </div>
                        <b-form @submit.prevent="resetPassword()" v-if="showForgotPassword" key="askForReset"
                                class="form">
                            <b-form-group :label="capitalize($t('form.field.email'))" class="mb-5">
                                <b-form-input
                                    id="input-1"
                                    autocomplete="false"
                                    v-model="email"
                                    type="email"
                                    required
                                    class="field"
                                ></b-form-input>
                            </b-form-group>
                            <div class="text-center">
                                <b-button type="submit" class="btn-live-pollen" :disabled="!email">
                                    {{
                                        firstConnexion ? capitalize($t('form.button.firstConnexionBtn')) : capitalize($t('form.button.forgotMyPassword'))
                                    }}
                                </b-button>
                            </div>
                            <div class="add-cursor text-center forgot-password">
                                <p @click="showForgotPassword = !showForgotPassword" class="clr-lightslategrey">
                                    {{ $t('form.button.cancel') }}</p>
                            </div>
                        </b-form>
                        <b-form @submit.prevent="connexion()" v-else key="login" class="form">
                            <b-form-group :label="capitalize($t('form.field.email'))">
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="email"
                                    required
                                    class="field"
                                    autocomplete="false"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mb-3" :label="capitalize($t('form.field.password'))">
                                <b-input-group>
                                    <b-form-input
                                        id="current-password"
                                        v-model="password"
                                        :type="showPassword ? 'text' : 'password'"
                                        required
                                        autocomplete="current-password"
                                        class="field"
                                    >
                                    </b-form-input>
                                    <b-input-group-prepend class="bg-snow add-cursor">
                                        <IconBase view-box="0 0 24 24" icon-color="#636D73" stroke-color="#636D73"
                                                  class-name="mt-2 mr-2" width="30px" height="22px"
                                                  @click.native="showPassword = !showPassword">
                                            <IconInvisible v-if="showPassword"/>
                                            <IconVisible v-else/>
                                        </IconBase>
                                    </b-input-group-prepend>
                                </b-input-group>
                            </b-form-group>
                            <b-form-checkbox class="mb-2" name="cgu" v-model="cgu"
                                             v-if="!localStorage.getItem('acceptedCgu') === true">
                                <span>{{ $t('form.cgu.part1') }}</span>
                                <b-link href="https://live-pollen.com/politique-de-confidentialite-superviseur/"
                                        target="_blank">
                                    {{ $t('form.cgu.link1') }}
                                </b-link>
                                <span>{{ $t('form.cgu.and') }}</span>
                                <b-link href="https://live-pollen.com/politique-cookies/" target="_blank">
                                    {{ $t('form.cgu.link2') }}
                                </b-link>
                                <span>{{ $t('form.cgu.part2') }}</span>
                            </b-form-checkbox>
                            <div class="text-center">
                                <b-button type="submit" class="btn-live-pollen"
                                          :disabled="!password || !email || cguNotAccepted()"
                                          v-if="!connexionIsLoading">{{ capitalize($t('form.button.connect')) }}
                                </b-button>
                                <b-button type="submit" class="btn-live-pollen" v-else>
                                    {{ capitalize($t('form.title.connection')) }}
                                    <b-spinner small></b-spinner>
                                </b-button>
                            </div>
                            <div class="add-cursor text-center forgot-password">
                                <p @click="showForgotPassword = !showForgotPassword" class="caption">
                                    {{ $t('form.button.forgotPassword') }}</p>
                            </div>
                        </b-form>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <vue-cookie-accept-decline
            :disable-decline="false"
            :show-postpone-button="false"
            element-id="cookiePanel"
            ref="cookiePanel"
            transition-name="slideFromBottom"
            type="bar"
            position="bottom"
            @clicked-accept="acceptCookie(true)"
            @clicked-decline="acceptCookie(false)"
        >
            <template #message>
                <iconBase view-box="100 100 250 220" width="65px" height="65px">
                    <Picto></Picto>
                </iconBase>
                <span>{{ $t('common.cookieContent') }}</span>
                <a href="https://live-pollen.com/politique-cookies/" target="_blank">{{
                        $t('common.cookiePolicy')
                    }}</a>
            </template>
            <template #declineContent class="btn-live-pollen outlined mr-2">Refuser</template>
            <template #acceptContent class="btn-live-pollen">Accepter</template>
        </vue-cookie-accept-decline>
    </b-container>
</template>

<script>
import Vuex from 'vuex'
import IconBase from "@/components/common/icons/IconBase";
import IconVisible from "@/components/common/icons/IconVisible";
import IconInvisible from "@/components/common/icons/IconInvisible";
import i18n from "@/i18n";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import Picto from "@/components/common/icons/picto.vue";

export default {
    name: "ConnexionForm",
    components: {Picto, IconInvisible, IconVisible, IconBase, VueCookieAcceptDecline},
    data() {
        return {
            email: '',
            password: '',
            cgu: false,
            cookie: false,
            alert: false,
            passwordType: 'password',
            showForgotPassword: false,
            showPassword: false,
            firstConnexion: false
        }
    },

    methods: {
        ...Vuex.mapActions('login', {
            logMeIn: 'logMeIn',
            askForPasswordReset: 'askForPasswordReset',
            logout: 'logout'
        }),
        async connexion() {
            this.alert = false;
            await this.logMeIn([this.email.toLowerCase().trim(), this.password]).then(() => {
                    if (this.authStatus.message && (this.authStatus.message.message === 'User doesn\'t exist' || this.authStatus.message.message === 'Invalid credentials')) {
                        this.alert = true
                    }
                    localStorage.setItem('acceptedCgu', 'true');
                }
            );
        },
        async resetPassword() {
            let query = this.generateQueryString()
            await this.askForPasswordReset(query);
        },
        generateQueryString() {
            let data = []
            let fields = new FormData();
            fields.set('email', this.email.toLowerCase().trim())
            if (this.firstConnexion) {
                fields.set('type', 'firstConnexionReset');
            }
            data.push(fields)
            return fields
        },
        capitalize(value) {
            return this.$options.filters.capitalize(value)
        },
        acceptCookie: function (accepted) {
            if (accepted) {
                if (process.env.VUE_APP_PROJECT_ENV === 'prod') {
                    this.cookie = true;
                    localStorage.setItem('acceptedCookies', 'accept');
                    this.$gtag.optIn();
                    this.$gtag.event('account', {
                        'event_category': 'Account',
                        'event_label': 'Account',
                        'value': this.currentAccount.name
                    })
                }
            } else {
                this.$gtag.optOut();
                localStorage.setItem('acceptedCookies', 'decline');
            }
        },
        cguNotAccepted() {
            return !(localStorage.getItem('acceptedCgu') || this.cgu);
        },

    },
    computed: {
        ...Vuex.mapGetters('login', {
            authStatus: 'authStatus',
            connexionIsLoading: 'getConnexionIsLoading',
            resetMailSent: 'resetMailSent'
        }),
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount'
        }),
        languages() {
            return [
                {value: 'fr', text: i18n.t('common.language.fr')},
                {value: 'en', text: i18n.t('common.language.en')},
            ]
        },
        localStorage() {
            return localStorage;
        }
    },
    watch: {
        resetMailSent: function (val) {
            if (val) {
                this.showForgotPassword = true
            }
        }
    },
    created() {
        if (this.$route.query.type && this.$route.query.type === 'firstConnexionReset') {
            this.firstConnexion = true;
            this.showForgotPassword = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.login-card {
    padding: 1vh 0;
    max-width: 600px;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

#language {
    position: absolute;
    top: 0;
    right: 0;
}
</style>
