<template>
    <b-container fluid>
        <b-row align-h="center" align-v="center" class="maintenance-content">
            <b-col cols="8" md="3">
                <IconBase view-box="0 0 360 384" width="100%" height="100%">
                    <IconMaintenance/>
                </IconBase>
            </b-col>
            <b-col cols="12" md="5" offset-md="1">
                <p class="maintenance-title">{{ $t(`error.${type}.title`) | capitalize}}</p>
                <p class="maintenance-message">{{ $t(`error.${type}.message`) }}</p>
                <b-button class="btn-live-pollen" @click="$router.push('/')">{{$t('common.button.backHome') | capitalize }}</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import IconBase from "@/components/common/icons/IconBase";
import IconMaintenance from "@/components/common/icons/IconMaintenance";

export default {
    name: "Error",
    components: {IconMaintenance, IconBase},
    props: {
        type: {
            type: String,
            default: 'notFound'
        }
    }
}
</script>

<style lang="scss" scoped>

.maintenance-content {
    height: 80vh;
    font-size: 3rem;

    .maintenance-title {
        color: $black;
        margin: 0;
    }

    .maintenance-message {
        color: $dimgrey;
        margin: 0;
    }
}
</style>